import React from 'react';
import { graphql } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
import Layout from '../../components/folioLayout';

const Thumbnails = ({ data }) => {
  const { images } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Container>
        {images.map((image, i) => (
          <Thumbnail to="/portfolio" state={{ slideIndex: i }}>
            <img src={image} alt="" />
          </Thumbnail>
        ))}
      </Container>
    </Layout>
  );
};

export default Thumbnails;

const Container = styled.div`
  width: 100%;
  padding: 4.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 500px) {
    padding: 0.75rem;
  }

  @media (min-width: 800px) {
    padding: 3.75rem;
  }
`;

const Thumbnail = styled(TransitionLink)`
  cursor: pointer;
  height: 7.5rem;
  margin: 0.5rem;
  border: none;

  img {
    display: block;
    height: 100%;
    width: auto;
  }

  @media (min-width: 500px) {
    height: 10rem;
    margin: 0.75rem;
  }

  @media (min-width: 800px) {
    height: 15rem;
    margin: 1.25rem;
  }

  @media (min-width: 1200px) {
    height: 20rem;
  }
`;

export const query = graphql`
  {
    markdownRemark(frontmatter: { title: { eq: "portfolio" } }) {
      frontmatter {
        images
      }
    }
  }
`;
