import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import SEO from './seo';
import GlobalStyles from '../styles/GlobalStyles';
import { PrevLocationContext } from '../contexts/prevLocationContext';
import { OverlayContext } from '../contexts/overlayContext';
import { BackgroundContext } from '../contexts/backgroundContext';
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link';

const Layout = ({ children }) => {
  const [closing, setClosing] = useState(false);
  const locationContext = useContext(PrevLocationContext);
  const overlayContext = useContext(OverlayContext);
  const backgroundContext = useContext(BackgroundContext);

  // if (!overlayContext.showOverlay) {
  overlayContext && overlayContext.setShowOverlay(false);
  // }

  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <Main>
          <SEO title="Portfolio" />
          <Overlay
            style={{
              background: backgroundContext?.backgroundColor,
              transform:
                transitionStatus === 'exiting'
                  ? 'translateY(0)'
                  : 'translateY(-100%)',
            }}
          />
          <Close
            style={{ opacity: closing ? 0 : 1 }}
            to={locationContext?.prevLocation}
            exit={{ trigger: () => setClosing(true), length: 0.7 }}
            entry={{
              delay: 0.7,
              length: 0,
            }}
          >
            <svg height="32" width="32">
              <line x1="11" y1="11" x2="21" y2="21" />
              <line x1="21" y1="11" x2="11" y2="21" />
            </svg>
          </Close>
          <ContentContainer
            style={{
              opacity: ['POP', 'entering', 'entered'].includes(transitionStatus)
                ? 1
                : 0,
            }}
          >
            <GlobalStyles />
            {children}
          </ContentContainer>
        </Main>
      )}
    </TransitionState>
  );
};

export default Layout;

const Main = styled.main`
  background-color: white;
`;

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #f8f3f3;
  z-index: 1000;
  transition: transform 400ms 300ms;
  will-change: transform;
`;

const ContentContainer = styled.div`
  transition: opacity 300ms;
  min-height: 100%;
`;

const Close = styled(TransitionLink)`
  height: 2rem;
  width: 2rem;
  position: fixed;
  top: 0;
  right: 0;
  border-radius: 50%;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  opacity: 0.5;
  transition: all 300ms;
  margin: 1.5rem;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    background: #f8f3f3;
    opacity: 1;
  }

  svg {
    display: block;
    fill: none;
    stroke: #6e514c;
    stroke-width: 1.5;
  }
`;
